import UTM from '/services/utm-carry.js'

UTM.store();

function setConfig(){
    window.config = {
        nextStartDate: null,
        ctaURLS: {
            main: "https://tibiaspe9ro.typeform.com/website-leads" + UTM.load("?"),
            pm: "https://tibiaspe9ro.typeform.com/pm-neu" + UTM.load("?"),
            sales: "https://tibiaspe9ro.typeform.com/sales-neu" + UTM.load("?"),
            kontakt: "https://tibiaspe9ro.typeform.com/to/Bl0H8zlO" + UTM.load("?"),
            quiz: "https://tibiaspe9ro.pro.typeform.com/to/E64yV8rs?utm_source=quiz" + UTM.load("?"),
            eignungstest: "https://tibiaspe9ro.typeform.com/to/XBvzbkK2" + UTM.load("?"),
            eignungstestsales: "https://tibiaspe9ro.typeform.com/to/koweMDcf" + UTM.load("?"),
            eignungstestweiterbildungen: "https://tibiaspe9ro.typeform.com/to/ZZaj5y7J" + UTM.load("?")
    
    
        },
        courseData: {
            pm: {
                name: "IT-Berater im Produktmanagement",
                duration: 10,
                minCertCount: 8,
                nextStartDate: "2025-02-26",
                kursnetID: 238306719
            },
            sales: {
                name: "IT-Vertriebsmanager & CRM Spezialist",
                duration: 8,
                minCertCount: 6,
                nextStartDate: "2025-01-30",
                kursnetID: 271937518
            }
        },
    };
}

function setNextGlobalStartDate(){
    var courseSlugs = Object.keys(window.config.courseData);
    var closestDate = null;
    courseSlugs.forEach(slug=>{
        if(!closestDate){
            closestDate = window.config.courseData[slug].nextStartDate;
        }else{
            if(new Date(window.config.courseData[slug].nextStartDate) < new Date(closestDate)){
                closestDate = window.config.courseData[slug].nextStartDate;
            }
        }
    });
    window.config.nextStartDate = closestDate;
}

window.getAppConfig = ()=>{
    setNextGlobalStartDate();
    return window.config;
};
window.addEventListener('reset-app-config', setConfig);

setConfig();
setNextGlobalStartDate();