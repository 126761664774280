import renderFeedback from '../components/feedback-box-component.js';

const tagName = "feedback-section";

var feedbackSectionCss = /*css*/`
    ${tagName} {
        display: block;
        position: relative;
        background: #f2f6f7;
        padding: 20px 0;
        overflow: hidden;
    }

    ${tagName} .blue-line {
        height: 0.5rem;
        background: var(--blue-wide-gradient);
        position: absolute;
        left: 0;
        right: 0;
    }

    ${tagName} .blue-line.top {
        top: 0px;
    }

    ${tagName} .blue-line.bottom {
        bottom: 0px;
    }

    ${tagName} .hero-2 {
        font-family: 'inter-bold', sans-serif;
        font-size: 30px;
        margin-bottom: 20px !important;
        margin-top: 10px;
        text-align: center;
    }

    ${tagName} .feedback-container {
        max-width: 80%;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
        justify-content: center; /* Center vertically */
    }

    ${tagName} .feedback-wrapper {
        display: flex;
        overflow: hidden;
        width: 100%;
        scroll-behavior: smooth;
    }

    ${tagName} .feedback-item {
        flex: 0 0 25%; /* Show 4 items at a time */
        box-sizing: border-box;
        padding: 10px;
    }


    ${tagName} .nav-button {
        background: none;
        border: none;
        color: var(--blue-brand);
        font-size: 2rem;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
    }

    ${tagName} .nav-button.left {
        left: -40px;
    }

    ${tagName} .nav-button.right {
        right: -40px;
    }

    ${tagName} .feedback-fade {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 30px;
        z-index: 1;
    }

    ${tagName} .feedback-fade.left {
        left: 0;
        background: linear-gradient(to right, #f2f6f7, transparent);
    }

    ${tagName} .feedback-fade.right {
        right: 0;
        background: linear-gradient(to left, #f2f6f7, transparent);
    }

    @media (max-width: 390px) {
        ${tagName} .feedback-fade { 
            display: none;
        }

        ${tagName} .feedback-item {
            justify-content: center; /* Center content */
            align-items: center; /* Center content vertically */
            padding: 0px;
        }

        ${tagName} .nav-button.left {
            left: -30px;
        }
    
        ${tagName} .nav-button.right {
            right: -30px;
        }
        
    }
    
`;

const renderFeedbackSection = (feedbackList) => {
    const feedbackItems = feedbackList.map(feedback => `
        <div class="feedback-item">
            ${renderFeedback(feedback)}
        </div>
    `).join('');

    return /*html*/`
    <div class="blue-line top"></div>
    <div class="hero-2">Das sagen unsere Kunden:</div>
        <div class="feedback-container">
            <button class="nav-button left">&#9664;</button>
            <div class="feedback-wrapper">
                ${feedbackItems}
            </div>
            <button class="nav-button right">&#9654;</button>
            <div class="feedback-fade left"></div>
            <div class="feedback-fade right"></div>
        </div>
    <div class="blue-line bottom"></div>
    <style>${feedbackSectionCss}</style>
    `;
};

class FeedbackSection extends HTMLElement {
    constructor(){
        super();
    }
    connectedCallback(){
        const feedbackList = [
            {
                text: "Ich bin jetzt seit über 2 Monaten in der Weiterbildung zum IT-Produktmanager beim FI und muss sagen von Anmeldung bis jetzt hat <mark>alle meine Erwartungen übertroffen</mark>. Wenn man Anliegen oder Fragen hat, werden diese innerhalb von kürzester Zeit beantwortet und/oder geklärt. Der Unterricht ist meiner Meinung <mark>sehr modern gestaltet</mark> und durch die <mark>Abwechslung von Theorie und Praxis</mark> kann man alles perfekt festigen! Im Großen und Ganzen kann ich in der kurzen Zeit sagen, <mark>ich fühle mich bestens betreut und geleert!</mark>",
                userName: "Janosch M.",
            },
            {
                text: "Top! Die Dozenten sind nicht nur <mark>mega kompetent</mark>, sondern auch super sympathisch. Man merkt einfach, dass hier <mark>jeden Tag mit Herz gearbeitet wird</mark>. Das Konzept ist durchdacht, und man lernt jeden Tag Neues dazu. Davor war ich bei einem anderen Anbieter und der Unterschied ist wie Tag & Nacht (Schulungen können tatsächlich auch Spaß machen!). Wer also überlegt, hier eine Weiterbildung zu starten, dem kann ich nur sagen: Macht es! :-)",
                userName: "Emily D.",
            },
            {
                text: 'Do it, the Franklin Way! Ich mach eine Umschulung zum Softwareberater/ produktmanager. <mark>Ich lerne jeden Tag etwas und habe Spaß bei der Arbeit!</mark> :D Am besten finde ich die offenen Dialoge die wir in der Gruppe führen. Der Unterricht wird in Theorie & Praxis unterteilt. Morgens bis zum Mittag haben wir Theorie und am Nachmittag wird dieses wissen dann meist mit passenden Praxisaufgaben ergänzt bzw. gefestigt. Das "on-Boarding" lief auch super entspannt ab. Unterlagen wurden mir alle vollständig zugeschickt, bei den "Amt-Gängen wurde" ich super beraten und vorbereitet und stehe seitdem im engen Kontakt mit meinem Buddy.',
                userName: "Tim L.",
            },
            {
                text: "Das Franklin Institute ist <mark>super organisiert und vor allem digitalisiert.</mark> Die persönliche Betreuung ist einwandfrei, mit einer <mark>guten Erreichbarkeit seines Kursbetreuers.</mark> Die Kurse sind gut strukturiert, organisiert und es gibt interaktive praktische Aufgaben. <mark>Es wird nicht langweilig.</mark> Ich kann das Franklin Institute jedem empfehlen, wenn man darüber nachdenkt sich weiterzubilden bzw. umzuschulen.",
                userName: "Aleksandar S.",
            },
            {
                text: '<mark>Eine völlig neue Art zu lernen, dennoch sehr effektiv,</mark> da es mich in meine natürliche neugierde - die Dinge zu erkunden - zurückbringt. <mark>Dozenten sind erste Sahne, natürlich, herzlich, kompetent, auf Augenhöhe und sehr verständnisvoll.</mark> Bin jetzt einen Monat dabei und freue mich jeden einzelnen Tag auf den Kurs und auf meine Kommilitonen. Empfehle den Kurs gerade für Menschen, welche sich mit Theorie schwertun, da ein großer Teil des Unterrichts aus Praxis besteht. Ich habe bereits jetzt schon so <mark>unfassbar viel dazugelernt,</mark> was gerade durch das "selbst ausprobieren" gefördert wurde und der Austausch mit den Kommilitonen soviel Mehrwert bietet. <mark>Absolute Empfehlung zu 100%.</mark>',
                userName: "Pamela L.",
            },
            {
                text: "<mark>Sehr professionelles und seriöses Institut!</mark> Ich wurde nicht nur ausführlich beraten, sondern auch intensiv bei dem Prozess für einen Bildungsgutschein mit der Agentur für Arbeit begleitet. Meine Beraterin Frau Lederer möchte ich hier besonders positiv hervor heben! Mit ihrer aufgeschlossenen, <mark>sehr sympathischen und emphatischen Art</mark> hat sie mir meine Ängste und Zweifel bezüglich einer Weiterbildung und dem Termin bei der Agentur für Arbeit genommen. Den Bildungsgutschein hätte ich ohne ihre Unterstützung nur schwer erhalten. Vielen Dank für die wunderbare Beratung!",
                userName: "Sinem Y.",
            },
            {
                text: "Die Beratung, die ich für meine Weiterbildung im Bereich IT-Beratung erhalten habe, <mark>war absolut erstklassig.</mark> Das Beraterteam war äußerst kenntnisreich und professionell. Sie nahmen sich die Zeit, um meine aktuellen Fähigkeiten und meine Karriereziele zu verstehen, und lieferten dann <mark>maßgeschneiderte Empfehlungen für Kurse und Zertifizierungen,</mark> die meinen Bedürfnissen entsprachen. Die Berater hatten ein tiefes Verständnis für die IT-Branche und waren in der Lage, mir klare Einblicke in die aktuellen Trends und Anforderungen zu geben. Sie erklärten ausführlich, wie die verschiedenen Weiterbildungsoptionen meine berufliche Entwicklung beeinflussen könnten, und beantworteten geduldig all meine Fragen. Die Kommunikation verlief reibungslos, und ich fühlte mich wirklich unterstützt und ermutigt, diese wichtige Entscheidung für meine Karriere voranzutreiben. Ich kann die <mark>IT-Weiterbildungsberatung uneingeschränkt empfehlen und danke dem Team herzlich für die herausragende Unterstützung!</mark>",
                userName: "Umair M.",
            },
            {
                text: "Unfassbar guter Kurs. <mark>Hoch qualifizierte Dozenten, viele Praxisaufgaben, die auch als alleinerziehender Vater machbar sind.</mark> War erst skeptisch, weil ich keine Vorerfahrung in dem Bereich habe, aber hat sich sehr schnell in Luft aufgelöst. Man wird dort abgeholt, wo man aktuell steht. Klar, es ist nicht super einfach, aber wenn man motiviert am Ball bleibt, dann ist das Kein Thema. Die Ansprechpartner stehen immer zur Verfügung, man macht hier wirklich einen Neustart und wird als Quereinsteiger top auf die neue Branche vorbereitet. <mark>Fühle mich sehr gut aufgehoben.</mark> Danke an Johannes und das Team!",
                userName: "Eric L.",
            },
            {
                text: 'Für mich als Unternehmensberater und experte aus der Industrie, ist diese Consulting und IT-Expert Weiterbildung zum "Product Owner" optimal, um sich einer immer werdenden digitalen Arbeitswelt mit neuen Qualifikationen anzupassen. Jedes Modul wird durch <mark>renommierte Zertifizierer wie der „Stanford University“, dem „TÜV Süd“</mark> oder der renommierten Produktmanagement-Beratung „Product People“ durchgeführt. Auch kann nur seriös das fachliche Wissen vermittelt werden, wenn man sich auch über diesen Mindestzeitraum von 10-12 Monate mit diesem sehr speziellen Themen Vollzeit beschäftigt. Nach fundierter Prüfung verschiedenster Institute am digitalen Bildungsmarkt habe ich diese Kombination wirklich nirgendwo so vorgefunden. Aus diesem Grund bin ich von dieser Weiterbildungsmaßnahme überzeugt und freue mich morgen meinen "Fernstudium" zum "Product Owner" beim Franklin Institute of Applied Science starten zu können.',
                userName: "Mario H.",
            },
            {
                text: "Ich bin komplett <mark>positiv überrascht von der Professionalität dieser Weiterbildung.</mark> Man lernt, mit den auf dem Markt gängigen Cloudprogrammen umzugehen. Es finden monatliche Feedbackgespräche per Einzel und Gruppensession statt, die auch bei Sinnhaftigkeit umgesetzt werden. Das Team ist super innovativ und die Lehrer von der Praxis. <mark>Ich bin froh, diesen Bildungsträger gewählt zu haben</mark> und würde ihn meinen Freunden und Familie zu 100% weiterempfehlen!",
                userName: "Chelsea E.",
            },
            {
                text: "Ich kann den Kurs und das Franklin Institute of Applied Sciences insgesamt <mark>uneingeschränkt empfehlen.</mark> Die Kombination aus erstklassigen Kursinhalten, motivierten Teilnehmern, innovativen Lernmethoden, exzellenter Betreuung, abwechslungsreichen Aktivitäten und angemessenem Zeitaufwand hat meine Erwartungen übertroffen. Wenn Sie nach einer Bildungseinrichtung suchen, die Ihnen eine <mark>hochwertige und bereichernde Lernerfahrung</mark> bietet, an der sie als Person individuell ihre Stärken mit in den Lernprozess einbringen können und NICHT “von oben herab druckbetankt” werden wollen, dann ist das Franklin Institute of Applied Sciences die richtige Wahl!",
                userName: "Ulf O.",
            },
        ];
        this.innerHTML = renderFeedbackSection(feedbackList);

        const container = this.querySelector('.feedback-wrapper');
        const leftButton = this.querySelector('.nav-button.left');
        const rightButton = this.querySelector('.nav-button.right');

        leftButton.addEventListener('click', () => {
            const itemWidth = container.querySelector('.feedback-item').clientWidth; // Get the width of one item
            container.scrollBy({
                left: -itemWidth, // Scroll by one item width
                behavior: 'smooth'
            });
        });
        
        rightButton.addEventListener('click', () => {
            const itemWidth = container.querySelector('.feedback-item').clientWidth; // Get the width of one item
            container.scrollBy({
                left: itemWidth, // Scroll by one item width
                behavior: 'smooth'
            });
        });
    }
}

customElements.define(tagName, FeedbackSection);

export default `<${tagName}></${tagName}>`;