import VermittlerHeroComponent from '/components/vermittler-hero-component.js';
import WarumFiasComponent from '../components/warum-fias-grid-component.js';
import CounterWidget from '../components/counter-widget-component.js';
import FCC6SchritteComponent from '/components/fcc-6-schritte-component.js';
import WarumPmSection from '/sections/warum-pm-section.js';
import Voraussetzungen from '../components/voraussetzungen-component.js';
import CourseCardVermittler from '/components/course-card-vermittler.js';
import DoubleCTABox from '../components/double-cta-box.js';
import WhiteCTABox from '../components/white-cta-box.js';
import AzavVerifiziertSection from '/sections/azav-verifiziert-section.js'
import VideoSection from '/sections/video-section.js';

const tagName = "vermittler-new-page";

var pageTitle = "Für Arbeitsvermittler | Franklin Institute of Applied Sciences";
var pageDescription = "Wir geben durch Weiterbildungen und Arbeitsvermittlung Talenten mit unterschiedlichsten beruflichen Hintergründen die Möglichkeit, ihr volles Potenzial in der IT-Branche auszuschöpfen.";

var videoURL = "https://fast.wistia.com/embed/medias/yc67ol9ioj";

var css = /*css*/`

.blue-line {
    height: 0.5rem;
    background: var(--blue-wide-gradient);
    left: 0;
    right: 0;  
    position: relative;

}

.blue-line.top {
    top: 0px;
}

.blue-line.bottom {
    bottom: 0px;
    z-index: 2;
}

${tagName} .full-width-image {
    width: 110%;
    display: flex;
    justify-content: center;
    margin: 0;
    margin-left: -60px;
  }
  
  ${tagName} .full-width-image img {
    width: 120%; /* Adjusted to 100% for proper scaling */
    height: auto;
    display: block;
    margin: 0 auto; /* Center the image */
    object-fit: cover; /* Ensures the image proportionally covers the container */
  }
   

${tagName} .course-card-cont {
  display: flex;
  gap: 2em;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

${tagName} .course-card-cont course-card {
  width: 450px;
}

@media (max-width: 490px) {
  ${tagName} .course-card-cont {
      gap: 1em;
  }
}

${tagName} .course-card-title {
  font-family: 'inter-bold';
  font-size: clamp(28px, 6.18vw, 45px);
  text-align: center;
  margin-bottom: 0.8em;
  padding-top: 30px;
}
`;

function template(){
    return /*html*/`
  <vermittler-hero-component></vermittler-hero-component>
  <warum-fias-grid-component></warum-fias-grid-component>
  <counter-widget-component></counter-widget-component>
    <div class="wrapper">
        <div class="course-card-title" id="courses">Unsere Weiterbildungen</div>
        <div class="course-card-cont">
            ${CourseCardVermittler({
                title: "IT-Berater im Produktmanagement",
                bulletPoints: [
                    "Inkl. Karrierecoaching",
                    "Inkl. MacBook",
                    `${config.courseData.pm.minCertCount} anerkannte Zertifikate + IHK Zertifikat`
                ],
                imageURL: "/assets/images-webp/course-banner-img-pm.webp",
                ctaURL: "/redirect?to=infomaterial-av"
            })}
            ${CourseCardVermittler({
                title: "IT-Vertriebsmanager & CRM Spezialist",
                bulletPoints: [
                    "Inkl. Karrierecoaching",
                    "Inkl. MacBook",
                    `${config.courseData.sales.minCertCount} anerkannte Zertifikate + IHK Zertifikat`
                ],
                imageURL: "/assets/images-webp/course-banner-img-sales.webp",
                ctaURL: "/redirect?to=infomaterial-sales"
            })}
        </div>
    </div>
    <azav-verifiziert-section></azav-verifiziert-section>
    ${WhiteCTABox({
        texts: [
            "Haben Sie Fragen?",
            "Wir beraten Sie gerne persönlich.",
            "Nutzen Sie hierfür einfach unser Kontaktformular oder rufen Sie uns jederzeit direkt an."
        ],
        ctas: [
            {label: "Jetzt Kontakt aufnehmen", href: config.ctaURLS.kontakt, internal: false, newTab: true},
            {label: "+49 89 62826149", href: "tel:+498962826149"}
        ]
    })}
</div>

    <warum-pm-section></warum-pm-section>

    <div class="fcc-section-wrapper" style="padding-top: 25px;">
    <fcc-6-schritte-component></fcc-6-schritte-component>
    <!-- Title Section -->
    <div class="video-title" style="text-align: center; color: var(--gray-brand); font-family: 'inter-bold', sans-serif; font-size: clamp(28px, 6.18vw, 45px); margin: 20px 0;">
    Unsere Arbeit im <span style="color: var(--blue-brand);">FCC</span> für <br> einen
    <span style="color: var(--blue-brand);"> Start in deinem Traumjob</span>
    </div>

    <!-- Video Section -->
    ${VideoSection(videoURL)}
    </div>
    

    <div class="full-width-image">
    <img src="/assets/images/team-people-img-desktop.png" alt="Team Image">
    </div>
    <div class="blue-line bottom"></div>


    <voraussetzungen-component></voraussetzungen-component>

    <div class="double-cta-cont" style="padding: 3em 1rem; background: linear-gradient(to bottom, white 0%, white 50%, var(--gray-brand) 50%, var(--gray-brand) 100%);">
        ${DoubleCTABox({
            texts: [
                "Haben Sie Fragen?",
                "Wir beraten Sie gerne persönlich.",
                "Nutzen Sie hierfür einfach unser Kontaktformular oder rufen Sie uns jederzeit direkt an."
            ],
            ctas: [
                {label: "Jetzt Kontakt aufnehmen", href: config.ctaURLS.kontakt, internal: false, newTab: true},
                {label: "+49 89 62826149", href: "tel:+498962826149"}
            ]
        })}
    </div>

    <style>${css}</style>
        
    `;
}

// Define the main page component
class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template();
    }
}

// Register the main page component
customElements.define(tagName, Page);

// Export the main component if needed
export default `<${tagName}></${tagName}>`;