const tagName = "certificate-component-sales";

var css = /*css*/`
    ${tagName} {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
    }

    .zertifikate-box {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(3, 1fr) !important; /* Default: three columns */
        grid-template-rows: auto !important; /* Adjust row height based on content */

        grid-gap: 20px; /* Space between grid items */
        justify-items: center;
    }
  

    .zertifikate-box-point {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: white;
        background-image: linear-gradient(137.51deg, #FFFFFF -8.85%, #959595 152.17%);
        color: var(--gray-brand);
        padding: 8px 8px;
        border-radius: 4px;
        gap: 10px;
        width: 220px;
        height: 120px;
        position: relative;
        overflow: hidden;
        transition: transform 0.2s;
        cursor: grab;
        user-select: none;
    }

    body.has-hover .zertifikate-box-point:hover {
        transform: translateY(-5px);
    }

    .certificate-title {
        font-family: 'inter-medium';
        font-size: 18px;
    }

    .certificate-title-product-school {
        font-family: 'inter-medium';
        font-size: 17px;
    }

    .zertifikat-badge {
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.7);
        font-size: 12px;
        background: rgba(255, 216, 77, 0.7);
        padding: 3px 7px;
        border-radius: 4px;
    }
    
    .zertifikat-badge-blue {
        background: #99EEF7;
        color: rgba(0, 0, 0, 0.7);
        font-size: 12px;
        padding: 3px 7px;
        border-radius: 4px;
    }
    
    .zertifikat-badge-green {
        background: rgba(178, 255, 205, 0.7);
        color: rgba(0, 0, 0, 0.7);
        font-size: 12px;
        padding: 3px 7px;
        border-radius: 4px;
    }

    .zertifikate-box-point * {
        z-index: 2;
    }

    .zertifikate-box-point:before {
        content: '';
        display: block;
        position: absolute;
        background-image: linear-gradient(-65deg, transparent 26%, rgba(255, 255, 255, 0.2) 27%, rgba(255, 255, 255, 0.2) 29%, transparent 30%, transparent 39%, rgba(255, 255, 255, 0.4) 40%, rgba(255, 255, 255, 0.4) 50%, transparent 51%, transparent 55%, rgba(255, 255, 255, 0.2) 56%, rgba(255, 255, 255, 0.2) 64%, transparent 65%, transparent 69%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0.2) 71%, transparent 72%);
        width: 100%;
        height: 100%;
        top: 0;
        left: -100%;
        z-index: 1;
        animation: 2s shine infinite linear;
        animation-delay: inherit;
        mix-blend-mode: luminosity;
    }

    .zertifikat-logo {
        position: absolute;
        top: 10px;
        right: 8px;
        width: auto;
        height: auto;
        max-width: 90px;
        max-height: 90px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    /* Specific logo styles */
    .zertifikat-logo-hubspot {
        width: 70px;
        aspect-ratio: 2/1;
        top: -1px;
        background-image: url('/assets/images/hubspot-logo.png');
    }

    .zertifikat-logo-dvnlp {
        right: 8px;
        bottom: unset;
        width: 70px;
        aspect-ratio: 2/1;
        top: -1px;
        background-image: url('/assets/images/dvnlp-logo.png');
    }

    .zertifikat-logo-salesforce {
        right: -17px;
        bottom: unset;
        width: 90px;
        aspect-ratio: 2/1;
        top: 0px;
        background-image: url('/assets/images/salesforce-logo.webp');
    }

    .zertifikat-logo-fias {
        right: 8px;
        bottom: unset;
        width: 75px;
        aspect-ratio: 3/1;
        top: 7px;
        background-image: url('/assets/images/fias-logo-new.svg');
    }

    .zertifikat-logo-fias-white {
        right: 8px;
        bottom: unset;
        width: 70px;
        aspect-ratio: 3/1;
        background-image: url('/assets/images/fias-logo-new-white.svg');
    }

    @keyframes shine {
        0% {
            left: -100%;
        }
        25% {
            left: 100%;
        }
        100% {
            left: 100%;
        }
    }

    @media (max-width: 1119px) {
        .zertifikate-box {
            grid-template-columns: 1fr 1fr !important; /* Two columns for screens narrower than 1119px */
        }
    }
    
    @media (max-width: 599px) {
        .zertifikate-box {
            grid-template-columns: 1fr !important; /* One column on very small screens */
        }
    }
`;

const renderInnerHTML = (options) => {
    // Determine the logo class based on the logoURL or another property
    let logoClass = '';
    if (options.logoURL.includes('hubspot-logo.png')) {
        logoClass = 'zertifikat-logo-hubspot';
    } else if (options.logoURL.includes('dvnlp-logo.png')) {
        logoClass = 'zertifikat-logo-dvnlp';
    } else if (options.logoURL.includes('salesforce-logo.webp')) {
        logoClass = 'zertifikat-logo-salesforce';
    } else if (options.logoURL.includes('fias-logo-new.svg')) {
        logoClass = 'zertifikat-logo-fias';
    } else if (options.logoURL.includes('fias-logo-new-white.svg')) {
        logoClass = 'zertifikat-logo-fias-white';
    }

    // Determine the badge class based on the badgeText content
    let badgeClass = '';
    if (options.badgeText.includes('+ Zertifikat')) {
        badgeClass = 'zertifikat-badge';
    } else if (options.badgeText.includes('+ Praxis Portfolio')) {
        badgeClass = 'zertifikat-badge-green';
    } else if (options.badgeText.includes('+ Report')) {
        badgeClass = 'zertifikat-badge-blue';
    }

    // Determine the title class based on the title content
    let titleClass = 'certificate-title';
    if (options.title.includes('User Research Zertifikat der Interaction Design Foundation')) {
            titleClass = 'certificate-title-product-school';
    }

    return /*html*/`
        <div class="zertifikate-box-point">
            <div class="${badgeClass}">${options.badgeText}</div>
            <div class="${titleClass}">${options.title}</div>
            <div class="zertifikat-logo ${logoClass}"></div>
            <style>${css}</style>
        </div>
    `;
};

class CertificateComponentSales extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.options = JSON.parse(this.getAttribute('element-options'));
        // console.log('Received data:', this.options);  // Log the data
        this.innerHTML = renderInnerHTML(this.options);
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CertificateComponentSales);

function render(options){
    return `<${tagName} element-options='${JSON.stringify(options)}'></${tagName}>`;
}

export default render;
