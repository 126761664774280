import renderCertificate from './black-certificate-component.js';

const tagName = "inverted-module-box-component";

var css = /*css*/`
${tagName} .module-zertifikate-grid-inverted {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 3fr; /* One column for numbers, three for the content */
    gap: 40px;
    align-items: start; /* Align items at the top of the grid */
}

${tagName} .modul-number-inverted {
    font-size: 110px;
    color: black;
    text-align: right;
    position: relative;
    align-self: start; /* Align number to the top of the grid cell */
    z-index: 2;
    top: 10px;
    background-image: url('/assets/images/number-background.svg');
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 200px; /* Adjust width to fit the background */
    height: 200px; /* Adjust height to fit the background */
    padding-right: 50px; /* Adjust padding to align the text inside the background */
    box-sizing: border-box; /* Ensure padding doesn't affect the overall size */
}

${tagName} .modul-box-inverted {
    position: relative; /* Ensure the title stays within the box */
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px 30px;
    padding-top: 45px;
    border-radius: 14px;
    background: white;  /* Background is white */
    color: var(--gray-brand);
    border: 6px solid #5AE3F0;
}

${tagName} .modul-box-title-inverted {
    position: absolute;
    top: -22px;
    left: -25px;
    padding: 0.6em 1em;
    border-radius: 6px;
    background: var(--gray-brand-gradient);
    color: white;
    z-index: 2;
    grid-column: 2; /* Place the title in the second column */
    font-family: 'inter-bold';
}

${tagName} .bulletpoint {
    font-size: 1rem;
    margin-bottom: 10px;
    font-family: 'inter-bold';
}

${tagName} .bulletpoint:before {
    margin: 0 !important;
    margin-right: 0.5em !important;
    display: inline-flex;
    content: '';
    min-width: 12px;
    min-height: 12px;
    background-image: url('/assets/images/blue-arrow-double.svg');
    background-size: 100% 100%;
    transform: rotate(-90deg);
}

/* Responsive adjustments */

@media (max-width: 857px) {

    ${tagName} .modul-number-inverted {
        font-size: 70px; /* Reduce the font size */
padding-top: 17px;
padding-right: 50px;
        width: 170px; /* Adjust the width */
        height: 170px; /* Adjust the height */
        background-size: 80%; /* Adjust background size */
        background-position: center; /* Center the background */
    }
}

@media (max-width: 809px) {
    ${tagName} .modul-number-inverted {
        display: none; /* Hide the number */
    }

    ${tagName} .module-zertifikate-grid-inverted {
        grid-template-columns: 1fr; /* Make the module box take the full width */
    }
}
`;

const renderInnerHTML = (options) => {
    return /*html*/`
        <div class="module-zertifikate-grid-inverted">
            <div class="modul-number-inverted">${options.number}</div>
            <div class="modul-box-inverted">
                <div class="modul-box-title-inverted">${options.title}</div>
                <div class="bulletpoints">
                    ${options.bulletpoints.map(point => `<div class="bulletpoint">${point}</div>`).join('')}
                </div>
                <div class="zertifikate-box">
                    ${options.certificates.map(cert => renderCertificate(cert)).join('')}
                </div>
            </div>
        </div>
        <style>${css}</style>
    `;
};

class ModuleBoxComponent extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.options = JSON.parse(this.getAttribute('element-options'));
        // console.log('Module Box Options:', this.options);
        this.innerHTML = renderInnerHTML(this.options);
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, ModuleBoxComponent);

function render(options){
    return `<${tagName} element-options='${JSON.stringify(options)}'></${tagName}>`;
}

export default render;
