// File: /services/blog.service.js

export async function getAllBlogPosts() {
  try {
    const xAK = "lkjsgfgbhkshgusiedorghdskfjvas";
    const limit = 10;
    const skip = 0;
    const url = `https://app.franklin-institute.de/api-external/blog/getBlogPosts?limit=${limit}&skip=${skip}`;
    const headers = { "x-api-key": xAK };

    const response = await fetch(url, { headers });
    const data = await response.json();

    // If data is an array, just return it
    if (Array.isArray(data)) {
      return data;
    }
    // Otherwise return an empty array
    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

// fetch ONE blog post by its ID (not strictly needed for slug-only approach)
export async function getBlogPostById(blogId) {
  try {
    const xAK = "lkjsgfgbhkshgusiedorghdskfjvas";
    const finalUrl = `https://app.franklin-institute.de/api-external/blog/getBlogPostById?id=${blogId}`;
    const headers = { "x-api-key": xAK };

    const response = await fetch(finalUrl, { headers });
    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
