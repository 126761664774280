const tagName = "footer-cont";

var css = /*css*/`
    ${tagName} {
        display: block;
        background: var(--gray-brand);
        color: white;
        padding-bottom: calc(var(--cookie-banner-height) + 0.5em);
        padding-top: 3em;
    }
    ${tagName} .footer-upper-cont {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3em;
        flex-wrap: wrap;
        gap: 2.5em;
        width: 100%;
    }
    ${tagName} .footer-lower-cont {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3em;
        flex-wrap: wrap;
        gap: 3em;
    }
    ${tagName} .white-logo {
        display: block;
        width: 18em;
        max-width: 100%;
        aspect-ratio: 255 / 79;
        background-image: url('/assets/images/fias-logo-new-white.svg');
        margin-bottom: 1em;
    }
    ${tagName} .white-slogan span {
        color: var(--blue-brand);
    }
    ${tagName} .contact-img {
        width: 70px;
        min-width: 70px;
        aspect-ratio: 1/1;
        background-image: url('/assets/images/emil_portrait_footer.jpg');
        border-radius: 100%;
    }
    ${tagName} .contact-cont {
        display: flex;
        gap: 16px;
        margin-bottom: 22px;
    }
    ${tagName} .contact-text-name {
        font-size: 22px;
    }
    ${tagName} .social-media-icons {
        display: flex;
        gap: 20px;
        margin-bottom: 16px;
    }
    ${tagName} .social-media-icon {
        width: 38px;
        aspect-ratio: 1/1;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        transition: 200ms;
    }
    ${tagName} .social-media-icon:hover {
        transform: translateY(-2px);
    }
    ${tagName} .social-media-icon-facebook {
        background-image: url('/assets/images/icons/icon-fb.svg');
    }
    ${tagName} .social-media-icon-linkedin {
        background-image: url('/assets/images/icons/icon-li.svg');
    }
    ${tagName} .social-media-icon-instagram {
        background-image: url('/assets/images/icons/icon-ig.svg');
    }
    ${tagName} .social-media-icon-tiktok {
        background-image: url('/assets/images/icons/icon-tt.svg');
    }
    ${tagName} .footer-links-cont {
        display: flex;
        gap: 2.5em;
        flex-wrap: wrap;
    }
    ${tagName} .footer-link-block {
        display: flex;
        flex-direction: column;
        gap: 0.8em;
        flex-grow: 1;
    }
    ${tagName} .footer-link-block-title {
        font-size: 22px;
        font-family: 'inter-medium';
    }
    ${tagName} .footer-link-block-links {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
        color: #D3D3D3;
    }
    ${tagName} .footer-link-block-links a {
        font-size: 18px;
    }
    ${tagName} .copyright {
        width: 100%;
        text-align: center;
        color: lightgray;
        margin-top: 1em;
    }

    ${tagName} .footer-logo-section {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 1.5em;
        margin-top: -50px;
        margin-bottom: -20px;
    }
    
    ${tagName} .footer-logo {
        display: block;
        width: 150px; 
        height: 150px;
        background-size: contain; 
        background-repeat: no-repeat; 
        background-position: center; 
    }
    ${tagName} .footer-germany-logo {
        background-image: url('/assets/images/Schland_2.png');
        margin-top: 5px;
    }
    ${tagName} .footer-bildung-logo {
        background-image: url('/assets/images/bilungsverband.png');
    }
    ${tagName} .footer-certqua-logo {
        background-image: url('/assets/images/Certqua_Zeichen_AZAV.png');
    }
`;

var template = /*html*/`
    <div class="wrapper">
        <div class="footer-upper-cont">
            <div>
                <a href="/" internal class="white-logo bg-img-contain" aria-label="Startseite"></a>
                <div class="white-slogan">
                    <h1 class="no-margin">Wir leben <span>Karriere.</span></h1>
                </div>
            </div>
            <div class="contact-social-media-cont">
                <div class="contact-cont">
                    <div class="contact-img bg-img-contain"></div>
                    <div class="contact-text">
                        <div class="contact-text-name">Dr. Emil Kendziorra</div>
                        <div class="contact-text-title">Bildungsbeauftragter</div>
                    </div>
                </div>
                <div class="social-media-icons">
                    <a class="social-media-icon social-media-icon-linkedin" target="_blank" href="https://de.linkedin.com/school/franklin-institute-of-applied-sciences-pioneer-people-gmbh/" aria-label="Franklin Institute LinkedIn Seite"></a>
                    <a class="social-media-icon social-media-icon-facebook" target="_blank" href="https://m.facebook.com/people/Franklin-Institute-of-Applied-Sciences/100088693976239/" aria-label="Franklin Institute Facebook Seite"></a>
                    <a class="social-media-icon social-media-icon-instagram" target="_blank" href="https://www.instagram.com/franklin_ias/" aria-label="Franklin Institute Instagram Seite"></a>
                    <a class="social-media-icon social-media-icon-tiktok" target="_blank" href="https://www.tiktok.com/@franklin_institute" aria-label="Franklin Institute Tiktok Seite"></a>
                </div>
                <a href="mailto:beratung@franklin-institute.de" class="hover-blue">beratung@franklin-institute.de</a>
            </div>
        </div>
        <div class="footer-lower-cont">
            <div class="footer-links-cont">
                <div class="footer-link-block">
                    <div class="footer-link-block-title">Franklin Institute</div>
                    <div class="footer-link-block-links">
                        <a href="/#faq" internal class="hover-blue">Häufige Fragen</a>
                        <a href="/about" internal class="hover-blue">Über das FI</a>
                        <a href="/vermittler" internal class="hover-blue">Für Vermittler</a>
                        <a href="/partner" internal class="hover-blue">Für Unternehmen</a>
                        <a href="/career" internal class="hover-blue">Karriere beim FI</a>
                    </div>
                </div>
                <div class="footer-link-block">
                    <div class="footer-link-block-title">Kursangebot</div>
                    <div class="footer-link-block-links">
                        <a href="/courses/pm" internal class="hover-blue">Product Management</a>
                        <a href="/courses/sales" internal class="hover-blue">IT-Sales Manager</a>
                    </div>
                </div>
                <div class="footer-link-block">
                    <div class="footer-link-block-title">Rechtliches</div>
                    <div class="footer-link-block-links">
                        <a href="/impressum" internal class="hover-blue">Impressum</a>
                        <a href="/datenschutz" internal class="hover-blue">Datenschutz</a>
                        <a onclick="eventBus.emit('show-cookie-banner')" class="hover-blue" style="cursor: pointer;">Cookie Einstellungen</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-logo-section">
            <div class="footer-logo footer-germany-logo"></div>
            <div class="footer-logo footer-bildung-logo"></div>
            <div class="footer-logo footer-certqua-logo"></div>
        </div>
        <div class="copyright">© ${new Date().getFullYear()} Pioneer People GmbH</div>
    </div>
    <style>
        ${css}
    </style>
`;

class CustomElem extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = template;
    }
}

customElements.define(tagName, CustomElem);

export default "<footer-cont></footer-cont>";
