import renderModuleBox from '../components/module-box-component-pm.js';
import renderInvertedModuleBox from '../components/inverted-module-box-component-pm.js';

const tagName = "certificate-section";

var css = /*css*/`
    ${tagName} {
        padding: 2rem;
        background: var(--light-gray);
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    ${tagName} .centered-text {
        text-align: center;
        margin-bottom: 0.1em;
        font-family: 'inter-bold';
        font-size: clamp(28px, 6.18vw, 60px);
    }

    ${tagName} .subheading-text {
        font-size: 1.3em;
        font-family: 'inter-medium';
    }

    ${tagName} .light-blue-text {
        color: var(--blue-brand);
    }
`;

const renderInnerHTML = (options) => {
    const normalModuleBoxesHTML = options.moduleBoxes.map(moduleBox => {
        return renderModuleBox(moduleBox);
    }).join('');

    const invertedModuleBoxHTML = renderInvertedModuleBox(options.invertedModuleBox);

    return /*html*/`
        <div class="section-message centered-text">
            <span style="font-size: clamp(28px, 6.18vw, 60px); font-family: 'inter-bold';">
                Der effektivste Start <br> in 
            </span>
            <span class="light-blue-text" style="font-size: clamp(28px, 6.18vw, 60px); font-family: 'inter-bold';">
                deine neue Karriere
            </span>
        </div>
        <div class="section-message centered subheading-text">Unser Kursaufbau und Karrierecoaching (Kurzfassung)</div>
        <div class="module-boxes-container">
            ${normalModuleBoxesHTML}
            ${invertedModuleBoxHTML}  
        </div>
        <style>${css}</style>
    `;
};

class CertificateSection extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.options = JSON.parse(this.getAttribute('element-options'));
        // console.log('Certificate Section Options:', this.options);
        this.innerHTML = renderInnerHTML(this.options);
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CertificateSection);

function render(options){
    return `<${tagName} element-options='${JSON.stringify(options)}'></${tagName}>`;
}

export default render;