import BlogCard from '/components/blog-card-component.js';
import '/config.js';
import '/services/util.js';
import { getAllBlogPosts } from '/services/blog.service.js';
import { slugify } from '/services/util.js';

const tagName = "blog-overview-page";

var css = /*css*/`
${tagName} .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px 20px;
    padding: 20px;
    padding-bottom: 40px;
    max-width: 1000px;
    margin: 0 auto;
}
${tagName} .hero-title {
    text-align: center;
    font-family: 'inter-bold', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
}

/* Skeleton styles */
${tagName} .skeleton-card {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 500px;
    border-radius: 0.8rem;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    overflow: hidden;
    background: #fff;
    animation: shimmer 1.5s infinite;
}
${tagName} .skeleton-thumbnail {
    width: 100%;
    height: 180px;
    background: #ddd;
}
${tagName} .skeleton-text {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0.8em;
}
${tagName} .skeleton-title {
    height: 1.6em;
    background: #ccc;
    border-radius: 0.3em;
    width: 70%;
    margin-top: 0.5em;
}
${tagName} .skeleton-title-bar {
    height: 1.6em;
    background: #ccc;
    border-radius: 0.3em;
    width: 90%;
    margin-bottom: 1em;
}
${tagName} .skeleton-summary {
    height: 1em;
    background: #ccc;
    border-radius: 0.3em;
    width: 100%;
}
${tagName} .skeleton-summary-bar {
    height: 1em;
    background: #ddd;
    border-radius: 0.3em;
    width: 100%;
    margin-bottom: 0.1em;
}
${tagName} .skeleton-read-more {
  display: inline-block;
  padding: 15px;
  background: var(--blue-brand, --gray-brand);
  color: #fff;
  border-radius: 0.4em;
  margin-top: 1.5em;
  margin-left: 1em;
  margin-right: 1em;
}

@media (max-width: 490px) {
  ${tagName} .skeleton-card {
    height: 450px;
  }
  ${tagName} .skeleton-read-more {
    margin-bottom: 0.82em;
    padding: 15px;
    margin-left: 0.8em;
    margin-right: 0.8em;
  }
  ${tagName} .skeleton-summary {
    height: 0.8em;
  }
  ${tagName} .skeleton-summary-bar {
    height: 0.8em;
  }
  ${tagName} .skeleton-title {
    height: 1.4em;
  }
  ${tagName} .skeleton-title-bar {
    height: 1.4em;
  }
  ${tagName} .skeleton-text {
    gap: 0.7em;
  }

}

@keyframes shimmer {
    0% { background-position: -200px 0; }
    100% { background-position: 200px 0; }
}
`;

function generateHTML(blogs) {
  if (!blogs || blogs.length === 0) {
    return `
            <div class="wrapper">
                <div class="hero-title">Latest Blogs</div>
                <p>No blogs found.</p>
                <style>${css}</style>
            </div>
        `;
  }

  return `
    <div class="wrapper">
      <div class="hero-title">Latest Blogs</div>
      <div class="blog-grid">
        ${blogs
      .map((blog) => {
        // Fallbacks in case the fields are empty
        const safeTitle = blog.title?.trim() || "Untitled Post";
        const safeSummary = blog.summary?.trim() || "No summary available";
        const safeReadTime = blog.read_time && blog.read_time > 0
          ? `${blog.read_time} min read`
          : "N/A";
        const safeThumbnail = blog.thumbnail?.trim()
          ? blog.thumbnail
          : "https://via.placeholder.com/300";

        // Instead of using blog.id for the link, use a slug from the safeTitle
        const slug = slugify(safeTitle);

        return '<a internal href ="/blog/' + slug + '">' + BlogCard({
          title: safeTitle,
          thumbnail: safeThumbnail,
          summary: safeSummary,
          read_time: safeReadTime,
          // NEW: Slug-based link
          link: `/blog/${slug}`
        }) + '</a>';
      })
      .join('')}
      </div>
    </div>
    <style>${css}</style>
  `;
}

class BlogOverviewPage extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    // Render skeleton cards
    this.innerHTML = `
          <div class="wrapper">
            <div class="hero-title">Latest Blogs</div>
            <div class="blog-grid">
              ${Array(3).fill(0).map(() => `
                <div class="skeleton-card">
                  <div class="skeleton-thumbnail"></div>
                  <div class="skeleton-text">
                    <div class="skeleton-title"></div>
                    <div class="skeleton-title-bar"></div>
                    <div class="skeleton-summary"></div>
                    <div class="skeleton-summary-bar"></div>
                    <div class="skeleton-summary-bar"></div>
                    <div class="skeleton-summary-bar"></div>
                  </div>
                  <div class="skeleton-read-more"></div>
                </div>
              `).join('')}
            </div>
            <style>${css}</style>
          </div>
        `;

    // Fetch blogs
    const blogData = await getAllBlogPosts();

    try {
      // Fetch blogs
      let blogData = await getAllBlogPosts();

      if (!Array.isArray(blogData)) {
        throw new Error("Invalid blog data format.");
      }

      // Sort the blogs by date_published in descending order (latest first)
      blogData.sort((a, b) => {
        const dateA = new Date(a.date_published);
        const dateB = new Date(b.date_published);

        // Handle invalid dates
        if (isNaN(dateA)) return 1; // Place invalid dates at the end
        if (isNaN(dateB)) return -1;

        return dateB - dateA; // Descending order
      });

      // Render the actual content
      this.innerHTML = generateHTML(blogData);
    } catch (error) {
      console.error("Error fetching or processing blog data:", error);
      this.innerHTML = `
            <div class="wrapper">
              <div class="hero-title">Latest Blogs</div>
              <p>Failed to load blogs. Please try again later.</p>
              <style>${css}</style>
            </div>
          `;
    }
  }
}

customElements.define(tagName, BlogOverviewPage);

export default `<${tagName}></${tagName}>`;
