import HeroButton from '/components/hero-button.js'
import FeatureCard from '/components/feature-card.js'
import '/config.js'

const tagName = "product-hero-section-skeleton";

const heroData = {
    pm: {
        text: /*html*/`IT-Berater im<br>Produktmanagement`,
        img: "/assets/images-webp/product-hero-img-pm.webp"
    },
    sales: {
        text: /*html*/`IT-Vertriebsmanager <br>& CRM Spezialist`,
        img: "/assets/images-webp/product-hero-img-sales.webp"
    },
};

var topSectionCSS = /*css*/`
    ${tagName} .product-hero-main-cont {
        display: flex;
    }
    ${tagName} .product-hero-content-title {
        font-size: clamp(1.5em, 7.1vw, 2.4em);
        font-family: 'inter-bold';
        letter-spacing: -0.01em;
        line-height: 1.1em;
    }
    ${tagName} .product-hero-content-title span {
        text-decoration: underline;
        text-decoration-color: var(--blue-brand);
    }
    ${tagName} .product-hero-content-cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.3em;
        flex: 1;
    }
    ${tagName} .product-hero-visual-cont {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    ${tagName} .product-hero-img {
        aspect-ratio: 2/3;
        width: 100%;
        max-width: 340px;
        margin: auto;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 11px;
        position: relative;
        overflow: hidden;
    }
    ${tagName} .hero-img-partner-cont {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        padding: 0.8em;
        gap: 0.8em;
        box-sizing: border-box;
        background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
        background-size: 100% 100%;
    }
    ${tagName} .hero-img-partner {
        background: white;
        border-radius: 7px;
        flex: 1;
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        transition: transform 200ms;
    }
    ${tagName} .hero-img-partner:hover {
        transform: translateY(-3px);
    }
    ${tagName} .hero-img-partner span {
        font-size: 0.6rem;
        text-align: center;
    }
    ${tagName} .hero-img-partner-logo {
        display: flex;
        width: 100%;
        aspect-ratio: 55 / 18;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
    }
    ${tagName} .hero-img-partner-logo-ihk {
        background-image: url('/assets/images/ihk-akademie-muenchen-oberbayern.svg');
    }
    ${tagName} .hero-img-partner-logo-ixdf {
        background-image: url('/assets/images/ixdf-logo-black-medium.png');
    }
    ${tagName} .product-hero-content-bulletpoints {
        display: flex;
        flex-direction: column;
        gap: 0.2em;
    }
    ${tagName} .product-hero-content-bulletpoint:before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('/assets/images/blue-arrow-double.svg');
        transform: rotate(-90deg) translateX(-0.15em);
        margin-right: 0.5em;
    }
    ${tagName} .section-kursnet-link {
        transition: 200ms;
    }
    ${tagName} .section-kursnet-link:hover{
        color: var(--blue-brand);
    }
    ${tagName} .section-kursnet-link:before{
        content: "";
        display: inline-flex;
        width: 30px;
        aspect-ratio: 1 / 1;
        background-image: url('/assets/images/afa-logo.svg');
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin-right: 10px;
        transform: translateY(9px);
    }
    @media(max-width: 970px){
        ${tagName} .product-hero-main-cont {
            gap: 3em;
        }
        ${tagName} .product-hero-content-cont {
            flex: 1.5;
        }
        ${tagName} .product-hero-visual-cont {
            flex: 1;
        }
    }
    @media(max-width: 840px){
        ${tagName} {
            padding-top: 1rem;
        }
        ${tagName} .product-hero-main-cont {
            flex-direction: column;
            max-width: 550px;
            margin: auto;
        }
        ${tagName} .product-hero-content-title {
            text-align: center;
            margin-bottom: 0.5em;
        }
        ${tagName} .product-hero-content-cont {
            flex: 1.5;
        }
        ${tagName} .product-hero-visual-cont {
            flex: 1;
        }
    }
    @media(max-width: 470px){
        ${tagName} .product-hero-content-bulletpoints {
            gap: 0.5em;
        }
    }
    @media(max-width: 360px){
        ${tagName} .product-hero-main-cont {
            gap: 1.2em;
        }
    }
`;

var featureCardCSS = /*css*/`
    ${tagName} .feature-card-cont {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 45px;
        gap: 45px;
    }
    @media (max-width: 999px) {
        ${tagName} .feature-card-cont {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
        ${tagName} .feature-card-cont>*:nth-child(2){
            grid-column: 1 / 3;
        }
    
        ${tagName} .feature-card-cont>*:nth-child(1){
            grid-row: 2;
        }
    }
    @media(max-width: 599px){
        ${tagName} .feature-card-cont {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            gap: 20px;
        }
    
        ${tagName} .feature-card-cont>*:nth-child(2){
            grid-column: initial;
        }
    
        ${tagName} .feature-card-cont>*:nth-child(1){
            grid-row: initial;
        }
    }
    @media(max-width: 360px){
        ${tagName} .feature-card-cont {
            margin-top: 20px;
        }
    }
`;

var css = /*css*/`
    ${tagName} {
        display: block;
        padding-top: 2em;
        padding-bottom: 2rem;
        font-family: 'inter-medium';
        padding-bottom: 200px;
    }
    ${topSectionCSS}
    ${featureCardCSS}

    @media(max-width: 430px) and (max-height: 932px){
        ${tagName} .product-hero-content-bulletpoint {
            font-size: 13px;
            font-family: "inter-medium";
        }
    }

    @media(min-width: 971px) {
        ${tagName} .product-hero-main-cont {
            justify-content: center; 
            padding-left: 80px; 
        }
    }

/* General title section styling */
${tagName} .product-hero-content-title {
    margin-bottom: 0;
    padding-bottom: 0; 
}

/* Smaller title styling */
${tagName} .product-hero-content-title .smaller-title {
    font-size: 20px;
    font-family: 'inter-bold';
    text-decoration: none;
    line-height: 1.2em; 
    margin-bottom: 0; 
    padding-bottom: 0; 
}

/* Large title (IT-Berater im Produktmanagement) styling */
${tagName} .product-hero-content-title span {
    display: block;
    margin: 0; 
    padding: 0; 
}

/* Specific adjustment for the main title to bring it up */
${tagName} .product-hero-content-title span:last-child {
    margin-top: -1em; 
    line-height: 1.1em; 
}

${tagName} .google-review-cont {
    display: flex;
    gap: 1em;
    // max-width: 200px;
    align-items: center;
    margin-left: -5px;
}
${tagName} .google-review-cont > div {
    // flex: 1;
    height: fit-content;
}
${tagName} .google-review-img-left {
    width: 12em;
    max-width: 180px;
    aspect-ratio: 192 / 43;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    background-image: url('/assets/images-webp/google-review-img-new.webp');
}
${tagName} .google-review-right-side {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 0.8em;
}
${tagName} .google-review-right-top {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
}
${tagName} .google-review-stars {
    display: flex;
    gap: 4px;
}
${tagName} .google-review-star {
    width: 22px;
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    background-image: url('/assets/images/star-icon.svg');
}
${tagName} .google-review-rating {
    font-family: 'inter-bold';
}
${tagName} .google-review-right-bottom {
    font-family: 'inter-light';
}

@media (max-width: 430px) {
    ${tagName} .product-hero-content-title span:last-child {
        font-size: 35px; 
        line-height: 1.2em; 
        margin-top:-28px;

    }

    ${tagName} .product-hero-content-text{
        font-size: 16px;
        text-align: center;
        font-family: "inter-medium";

    }
}
`;

const renderInnerHTML = (options) => {
    return /*html*/`
        <div class="product-hero-main-cont">
            <div class="product-hero-content-cont">
                <div class="product-hero-content-title">
                <span class="skeleton-box"><span class="smaller-title">Sichere Dir Jetzt Deine <br> Online-Weiterbildung zum</span></span><br>
                    <span class="skeleton-box"><span>Internet Mensch</span></span>
                </div>
                <span class="skeleton-box">
                <div class="product-hero-content-text">
                    <b>In X Monaten aus der Arbeitslosigkeit zum Traumjob.</b>
                </div>
                </span>
                <div class="product-hero-content-bulletpoints">
                    <span class="skeleton-box"><div class="product-hero-content-bulletpoint"><strong>Kostenlose </strong> Weiterbildung für Arbeitssuchende ✅</div></span>
                    <span class="skeleton-box"><div class="product-hero-content-bulletpoint">Kostenloses <strong>MacBook</strong> inklusive 💻</div></span>
                    <span class="skeleton-box"><div class="product-hero-content-bulletpoint">Bequem von <strong>zu Hause aus</strong> lernen 🏠</div></span>
                    <span class="skeleton-box"><div class="product-hero-content-bulletpoint">Optimal für <strong>Quereinsteiger</strong> geeignet 📈</div></span>
                </div>
                <span class="skeleton-box"><a cta-prevent-inner-hover target="_blank" href="/">
                ${HeroButton(
                    "Kostenlose Beratung vereinbaren und <span>durchstarten.</span>"
                )}
            </a></span>
            
                <!-- Google Review Section -->
                <div class="google-review-cont" style="text-align: center; max-width: 800px;" skeleton-fade-in>
                    <div class="google-review-img-left"></div>
                    <div class="google-review-right-side">
                        <div class="google-review-right-top">
                            <div class="google-review-stars">
                                <div class="google-review-star"></div>
                                <div class="google-review-star"></div>
                                <div class="google-review-star"></div>
                                <div class="google-review-star"></div>
                                <div class="google-review-star"></div>
                            </div>
                            <div class="google-review-rating">5.0</div>
                        </div>
                        <div class="google-review-right-bottom">181+ Bewertungen</div>
                    </div>
                </div>
                <!-- End of Google Review Section -->
            </div>
            <div class="product-hero-visual-cont">
                <div class="product-hero-img-cont">
                    <div class="product-hero-img" skeleton-fade-in>
                        <div class="hero-img-partner-cont">
                            <a class="hero-img-partner">
                                <span></span>
                                <div class="hero-img-partner-logo"></div>
                            </a>
                            <a class="hero-img-partner">
                                <span></span>
                                <div class="hero-img-partner-logo"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="feature-card-cont">
            <div class="feature-card-skeleton" skeleton-fade-in></div>
            <div class="feature-card-skeleton" skeleton-fade-in></div>
            <div class="feature-card-skeleton" skeleton-fade-in></div>
        </div>
        <style>
            ${tagName} {
                --skeleton-bg-color: #E4E4E4;
            }
            ${tagName} .feature-card-skeleton {
                    width: 336px;
                    height: 132px;
                    background: var(--skeleton-bg-color);
                    border-radius: 20px;
                }
                ${tagName} {
                    position: relative;
                }
                ${tagName}:before {
                    position: absolute;
        content: '';
        display: block;
        width: 300%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(to left, transparent 0%, transparent 34%, rgba(255, 255, 255, 0.5) 50%, transparent 66%, transparent 100%);
        animation: infinite product-hero-skeleton-slide 1200ms;
        z-index: 1;
                }
                ${tagName} .product-hero-img {
                            background: var(--skeleton-bg-color);
                        }
                        ${tagName} .hero-img-partner-cont {
                            display: none !important;
                            background: transparent !important;
                        }
                        ${tagName} .google-review-img-left, .google-review-right-side {
                            background: var(--skeleton-bg-color) !important;
                            border-radius: 10px;
                        }
                        ${tagName} .google-review-right-side > * {
                            visibility: hidden;
                        }
                        ${tagName} .skeleton-box {
                        background: var(--skeleton-bg-color);
                        width: fit-content;
                        height: fit-content;
                        border-radius: 10px;
                    }
                    ${tagName} .skeleton-box > * {
                        visibility: hidden;
                    }
                    @keyframes product-hero-skeleton-slide {
                        0% {
                            transform: translateX(-67%);
                        }
                        100% {
                            transform: translateX(0%);
                        }
                        100% {
                            transform: translateX(0%);
                        }
                    }
                    ${tagName} .product-hero-skeleton-fade-in {
                        opacity: 0;
                        animation: 250ms product-hero-skeleton-fade-in linear forwards;
                    }
                    @keyframes product-hero-skeleton-fade-in {
                        0% {
                            opacity: 0;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
        </style>
        <style>${css}</style>
    `;
};


class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        // var d = window.decodeURIComponent;
        // this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML();
        this.removeAttribute('element-options');
        // this.setFadeIn();
    }

    setFadeIn(){
        var fadeInElements = Array.from(this.querySelectorAll('.skeleton-box, [skeleton-fade-in]'));
        var delayStep = 25;
        var delay = 0;
        fadeInElements.forEach(elem => {
            elem.classList.add('product-hero-skeleton-fade-in');
            elem.style.animationDelay = `${delay}ms`;
            delay += delayStep;
        });
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render