import ProductHeroSection from '/sections/product-hero-section.js'
import ProductHeroSkeleton from '/sections/product-hero-section-skeleton.js'
import EmployerMarquee from '/sections/employer-marquee.js'
import YTVideoSection from '/sections/yt-video-section.js'
import PartnerSection from '/sections/partner-section.js'
import DoubleCTABox from '/components/double-cta-box.js'
import FAQBox from '/components/faq-box.js'
import CheckmarkList from '/components/checkmark-list.js'
import FiasWhySectionVisual from '/sections/fias-why-section-visual.js'
import WideCTASection from '/sections/wide-cta-section.js'
import TeacherSection from '/sections/teacher-section.js'
import ProductInfoSection from '/sections/product-info-section.js'
import StartDateCTASection from '/sections/start-date-cta-section.js'
import '/config.js'
import '/services/util.js'
import CertificateSection from '/sections/certificate-section.js'
import renderBlueCertificate from '/components/blue-certificate-component.js';
import FeedbackSection from '/sections/feedback-section.js';
import DynamicCourseContent from '/components/dynamic-course-content.js';
import UTM from '/services/utm-carry.js'

const tagName = "dynamic-course-page";

const courseSlug = "pm";

var getPageTitle = (course)=>{return `${course.title_readability_optimized} | Franklin Institute of Applied Sciences`};
var getPageDescription = (course)=>{return `Starte deine Karriere mit der kostenlosen Weiterbildung ${course.title_readability_optimized}. In nur ${course.duration} Monaten von der Arbeitslosigkeit zum Traumjob, inklusive MacBook und IHK-Zertifikat. Perfekt für Quereinsteiger und 100% kostenfrei dank Bildungsgutschein. Sichere dir jetzt deinen Platz und nutze unsere umfassende Karriereberatung für einen erfolgreichen Einstieg in die IT-Branche.`};

var css = /*css*/`

${tagName} .feedback-section-wrapper {
        margin-bottom: -10px; 
    }
${tagName} .partner-section-wrapper {
    margin-top: 30px;
    margin-bottom: 10px; 
}
${tagName} .hero-1 {
    font-family: 'inter-bold', sans-serif;
    font-size: 2em;
    margin-bottom: 0.5em;
}
${tagName} .hero-2 {
    font-family: 'inter-bold', sans-serif;
    font-size: 1.6em;
    margin-bottom: 0.5em;
}
${tagName} .grow-font-2 {
    font-size: clamp(32px, 4.18vw, 46px) !important;
}
${tagName} .flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
`;

const certificateSectionData = {
    moduleBoxes: [
        {
            number: "1",
            title: "Grundlagen",
            bulletpoints: [
                "Einführung und digitaler Wandel",
                "Agile Methoden",
                "Nutzer- und Aufgabenanalyse sowie Produktanalyse und Marktbeobachtung"
            ],
            certificates: [
                {
                    badgeText: "+ Zertifikat",
                    title: "Product Analytics Zertifikat der Product School",
                    logoURL: "/assets/images/product-school-logo.svg"
                },
                {
                    badgeText: "+ Zertifikat",
                    title: "Scrum Master Zertifikat der Scrum Alliance",
                    logoURL: "/assets/images/scrum-alliance-logo.svg"
                },
                {
                    badgeText: "+ Zertifikat",
                    title: "User Research Zertifikat der Interaction Design Foundation",
                    logoURL: "/assets/images/ixdf-logo-black-small.png"
                },
                {
                    badgeText: "+ Zertifikat",
                    title: "Projektmanagement Zertifikat von Product People",
                    logoURL: "/assets/images/product-people-logo.png"
                }
            ]
        },
        {
            number: "2",
            title: "DeepDive 1.0",
            bulletpoints: [
                "Requirement-Engineering",
                "Designmanagement und Design Thinking",
                "User Experience und Praxis-Teil"
            ],
            certificates: [
                {
                    badgeText: "+ Zertifikat",
                    title: "Product Owner Zertifikat des TÜV Süd",
                    logoURL: "/assets/images/tuev-sued-logo.svg"
                }
            ]
        },
        {
            number: "3",
            title: "DeepDive 2.0",
            bulletpoints: [
                "Produktstrategie und Roadmap / Portfolio-Analysen",
                "Führung und Controlling für IT- und Software-Berater",
                "Persönliches Benchmarking und Effektivitätssteigerung",
                "Projektauswahl und Zielsetzung (PRAXIS MODUL)",
                "Umsetzungsphase (PRAXIS MODUL)",
                "Evaluation und Diskussion (PRAXIS MODUL)"
            ],
            certificates: [
                {
                    badgeText: "+ Zertifikat",
                    title: "Business Models Zertifikat der Stanford University",
                    logoURL: "/assets/images/stanford-university-logo-medium.png"
                },
                {
                    badgeText: "+ Zertifikat",
                    title: "Agile Leadership Zertifikat von Metagile",
                    logoURL: "/assets/images/metagile-logo.png"
                },
                {
                    badgeText: "+ Zertifikat",
                    title: "Applied IT-Consulting Zertifikat von Product People",
                    logoURL: "/assets/images/product-people-logo.png"
                },
                {
                    badgeText: "+ Praxis Portfolio",
                    title: "Praxis-Projekt-Portfolio",
                    logoURL: "/assets/images/fias-logo-new.svg"
                }
            ]
        }
    ],
    invertedModuleBox: {
        number: "4",
        title: "Franklin Career Center",
        bulletpoints: [
            "Karriere-Coaching & Persönlichkeitsanalyse",
            "Intensives Bewerber Coaching",
            "Arbeitsvermittlung an Partner Unternehmen",
            "Bewerbungsphase mit Individueller Betreuung"
        ],
        certificates: [
            {
                badgeText: "+ Report",
                title: "Ausführlicher Coaching Report",
                logoURL: "/assets/images/fias-logo-new-white.svg"
            }
        ],
        blueCertificate: {
            badgeText: "+ IHK-Zertifikat",
            title: "IHK-Abschluss*",
            logoURL: "/assets/images/ihk-logo.svg"
        }
    }
};

var salaryData = {
    'pm': {
        salaryString: "67.056 €",
        jobTitle: "IT-Berater im Produktmanagement"
    },
    'sales': {
        salaryString: "55.049 € + Bonus",
        jobTitle: "IT-Sales Manager"
    },
    'cyber': {
        salaryString: "69.698 €",
        jobTitle: "IT-Security Specialist"
    }
};

function generateHTML(course){
    var courseSlug = course.vertical.toLowerCase();
    if(courseSlug == "its"){courseSlug = "sales"}
    return /*html*/`
    <div class="wrapper">
        ${ProductHeroSection({
            dynamicCourse: course,
            courseSlug: (function(){if(courseSlug == 'cyber'){return 'pm'}else{return courseSlug}})(),
            featureCards: [
                {
                    title: `${salaryData[courseSlug].salaryString}`,
                    text: `Durchschnittliches Gehalt als ${salaryData[courseSlug].jobTitle} (Jährlich, Brutto)`
                },
                {
                    title: "MacBook inklusive",
                    text: "Um optimale Bedingungen für deine Teilnahme an unserer Weiterbildung zu schaffen, stellen wir dir ein MacBook zur Verfügung"
                },
                {
                    title: "Karrierechancen",
                    html: /*html*/`
                        <div style="display: flex; flex-direction: column; align-items: start; gap: 10px; margin-top: 5px;">
                            <div style="display: flex; align-items: center; gap: 10px;">
                                <div class="five-stars-icon-cont" style="display: flex; gap: 5px;">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>Sehr gut
                            </div>
                            <div style="font-size: 17px; font-family: inter-bold;">Vermittlungsquote: 93,7%</div>
                        </div>
                        <style>
                            ${tagName} .five-stars-icon-cont > div {
                                display: block;
                                width: 28px;
                                aspect-ratio: 1/1;
                                background-position: 50% 50%;
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-image: url('/assets/images/star-icon.svg');
                            }
                        </style>
                    `
                }
            ]
        })}
    </div>
    ${EmployerMarquee}
    <div class="wrapper">${DynamicCourseContent({modules: course.modules})}</div>
    <div class="wrapper" style="padding-top: 2rem; display: none;">
        ${/*YTVideoSection({courseSlug})*/""}
        <div>
        ${ProductInfoSection({courseSlug: (function(){if(courseSlug == 'cyber'){return 'pm'}else{return courseSlug}})()})}
        </div>
    </div>
    ${StartDateCTASection({courseSlug: (function(){if(courseSlug == 'cyber'){return 'pm'}else{return courseSlug}})()})}
    <style>
        ${tagName} .section-why {
            display: flex;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 3rem;
        }
        ${tagName} .section-why .two-col-wrapper {
            display: flex;
            justify-content: center;
        }
        ${tagName} checkbox-list {
            flex: 1.2;
        }
        ${tagName} fias-why-section-visual {
            flex: 1;
        }
        ${tagName} .section-why-main-cont {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3em;
            margin-top: 2.5em;
            padding: 0 3em;
        }
        @media(max-width: 1100px){
            ${tagName} .section-why-main-cont {
                gap: 2em;
                padding: 0 1em;
            }
            ${tagName} checkbox-list {
                flex: 1.3;
            }
            ${tagName} fias-why-section-visual {
                flex: 1;
            }
        }
        @media(max-width: 899px){
            ${tagName} .section-why-main-cont {
                flex-direction: column-reverse;
                max-width: 500px;
                margin-top: 1.5em;
            }
            ${tagName} .section-why {
                max-width: 500px;
            }
            ${tagName} checkbox-list {
                flex: initial;
            }
            ${tagName} fias-why-section-visual {
                flex: initial;
            }
        }
        @media(max-width: 400px){
            ${tagName} .section-why-main-cont {
                gap: 2em;
                padding: 0 0em;
            }
        }
    </style>
    <div class="section section-why">
        <div class="wrapper">
            <div class="flex-col-center">
                <div class="hero-1 grow-font-2">Warum das Franklin Institute?</div>
                <div class="hero-2" style="color: var(--blue-brand);">Profitiere von unserem einzigartigen Weiterbildungs-System</div>
            </div>
            <div class="section-why-main-cont">
                ${FiasWhySectionVisual}
                ${CheckmarkList([
                    {
                        title: "Zeitaufwand auf das mindeste reduziert!",
                        content: "Unsere Weiterbildung soll <b>in Dein Leben passen</b> und so schnell wie möglich dabei helfen, dass Du Deinen Weg in <b>Deine Traumkarriere</b> findest."
                    },
                    {
                        title: "100% Kostenlos - mit Bildungsgutschein",
                        content: "Bildung sollte bezahlbar, oder noch besser, <b>kostenlos</b> sein. Unsere Weiterbildung kannst Du <b>mit dem Bildungsgutschein komplett kostenlos absolvieren</b>. Bewerbe Dich einfach bei uns, <b>wir helfen Dir</b>, den Bildungsgutschein zu erhalten."
                    },
                    {
                        title: "Individuelle Betreuung - lernen in kleinen Gruppen",
                        content: "Damit Du in unserem einjährigen Karriereprogramm optimal auf Deinen neuen Job vorbereitet wirst, setzen wir auf <b>kleine, homogene</b> Lerngruppen."
                    },
                    {
                        title: "Unschlagbares Karriere-Programm für deinen Traumjob",
                        content: "Aufgrund der langen Erfahrung und der verschiedenen Karrierewege unseres Gründerteams verfügen wir über einen <b>unbezahlbaren Wissensschatz aus über 50 Jahren erfolgreicher Karrieren</b> in verschiedensten Bereichen. <b>Dieses Wissen wollen wir mit dir teilen, ohne Aufpreis, ohne Verpflichtungen.</b> Deshalb haben wir unser <b>Karriere- und Bewerbungscoaching</b> entwickelt um dir am Ende deiner Ausbildung das nötige Wissen für einen <b>Raketenstart in deine eigene erfolgreiche Karriere</b> zu geben! Freu dich darauf, das ist der <b>wohl wichtigste Teil deiner Weiterbildung beim Franklin Institute.</b>"
                    }
                ])}
            </div>
        </div>
    </div>
    <div class="wrapper" style="display: none;">
    ${CertificateSection(certificateSectionData)}
        <div class="blue-certificate">
            ${renderBlueCertificate(certificateSectionData.invertedModuleBox.blueCertificate)}
        </div>
    </div>

    <div class="wrapper">
        <div class="partner-section-wrapper"> ${PartnerSection} </div>
    </div>

    <div class="feedback-section-wrapper">
        ${FeedbackSection} 
    </div>

    <div class="wrapper">
    ${TeacherSection({courseSlug: (function(){if(courseSlug == 'cyber'){return 'pm'}else{return courseSlug}})()})}
    </div>

        
    ${WideCTASection({
        dynamicCourse: course,
        courseSlug,
        title: /*html*/`<div class="line">Starte jetzt mit der Weiterbildung</div> <div class="line"><span>${course.title_readability_optimized}</span></div>`,
        btnText: /*html*/`<span>Jetzt kostenlose Beratung vereinbaren</span>`
    })}
    <div class="section" style="margin: 1rem 0;">
        <div class="wrapper">
            <div class="page-hero-medium font-scale-down centered" style="text-align: center; margin-bottom: 3rem; margin-top: 4rem;" id="faq">Häufig gestellte Fragen:</div>
            ${FAQBox([
                {
                    q: "Was bringt mir eine Weiterbildung beim Franklin Institute?",
                    a: "Wir leben Karriere. Das ist unser Motto - Unsere Weiterbildungen sollen Dir genau das ermöglichen - Karriere. Wir bilden Dich über mehrere Monate in einem relevanten Beruf in der IT-Branche aus und unterstützen Dich dann beim Einstieg in deine Traumkarriere. Mit uns an Deiner Seite formen wir Deine Karriere und geben Dir alles an die Hand, was Du brauchst um beruflich erfolgreich zu sein."
                },
                {
                    q: "Welche Voraussetzungen muss ich erfüllen?",
                    a: "Um an der Weiterbildung & am Career Success Programm teilnehmen zu können, musst Du lediglich (bald) bei der Agentur für Arbeit oder beim Jobcenter arbeitslos gemeldet sein. Solltest Du dies nicht sein, ist die Teilnahme leider nicht möglich. Du wirst in absehbarer Zeit arbeitslos? Dann bewerbe Dich gerne über unser kurzes Formular bei uns."
                },
                {
                    q: "Fallen für die Weiterbildung Kosten an?",
                    a: "Wenn Du arbeitslos gemeldet bist, hast Du die Möglichkeit einen Bildungsgutschein vom Staat zu erhalten. Dieser Bildungsgutschein finanziert Dir die komplette Weiterbildung und unser Career Success Programm - somit ist unser gesamtes Angebot kostenlos für Dich. Bewerbe Dich gerne über unser kurzes Formular und wir helfen Dir dabei einen Bildungsgutschein zu erhalten."
                },
                {
                    q: "Kann ich die Weiterbildung von Zuhause aus absolvieren?",
                    a: "Ja - die komplette Weiterbildung inkl. Career Success Programm findet Remote statt. Somit hast Du die Möglichkeit von überall aus teilzunehmen. Du erhältst auch einen Laptop von uns."
                },
                {
                    q: "Erhalte ich nach meiner Weiterbildung ein anerkanntes Zertifikat?",
                    a: "Ja, da wir AZAV zertifiziert sind, erhältst du nach deiner Weiterbildung ein offizielles Abschluss-Zertifikat von uns. Zusätzlich erhältst du während unseren Weiterbildungen einige, in der IT-Branche sehr angesehene, Zertifikate von namhaften Anbietern. So stellen wir sicher, dass du am Ende neben deiner Praxiserfahrung auch die nötigen Dokumente für einen erfolgreichen Einstieg in die IT-Branche vorlegen kannst."
                }
            ])}
        </div>
    </div>
    <div class="double-cta-cont" style="padding: 3em 1rem; background: linear-gradient(to bottom, white 0%, white 50%, var(--gray-brand) 50%, var(--gray-brand) 100%);">
        ${DoubleCTABox({
            texts: [
                "Haben Sie Fragen?",
                "Wir beraten Sie gerne persönlich.",
                "Nutzen Sie hierfür einfach unser Bewerbungsformular."
            ],
            ctas: [
                {label: "Jetzt bewerben.", href: config.ctaURLS[courseSlug], internal: false, newTab: true}
            ]
        })}
    </div>
    <style>${css}</style>
`;
}

function generateLoadingHTML(){
    return /*html*/`<div class="wrapper">${ProductHeroSkeleton()}</div>`;
}

function parseCourseID(){
    return location.pathname.split("/course/")[1];
}

async function getCourseData(courseID){
    try{
        var xAK = atob("SkpTSEZFc2RmYmRqp1pSKEZaL0hKaUZkZmFzZGYzcQ==");
        var loc = atob("aHR0cHM6Ly9hcHAuZnJhbmtsaW4taW5zdGl0dXRlLmRlL2FwaS1leHRlcm5hbC9jb3Vyc2UtbGlzdGluZ3MvZ2V0Q291cnNlP2lkPQ==") + courseID;
        var hN = atob("eC1hcGkta2V5");
        var headers = {};
        headers[hN] = xAK;
        var response = await fetch(loc, {headers});
        var responseData = await response.json();
        if(!responseData.success){
            return null
        }else{
            return responseData.course
        }
    }catch(e){
        return null
    }
}

class Page extends HTMLElement {
    constructor(){
        super();
    }

    async connectedCallback(){
        var courseID = parseCourseID();
        var realCourseDataPromise = getCourseData(courseID);
        this.innerHTML = generateLoadingHTML();
        // return
        var sleep = new Promise(res => setTimeout(res, 300));
        // var sleep = new Promise(res => setTimeout(res, 0));
        var realCourseData = await realCourseDataPromise;
        // return
        if(!realCourseData){
            location.href = "/";
            return
        }
        this.storeUTMData(realCourseData);
        var courseType = realCourseData.vertical.toLowerCase();
        if(courseType == "its"){courseType = "sales"}
        if(courseType == "cyber"){courseType = null}
        var courseTypeEvent = new CustomEvent("update-main-menu", {detail: courseType});
        var mainMenu = document.querySelector('main-menu');
        mainMenu.dispatchEvent(courseTypeEvent);
        await sleep;
        util.setPageTitle(getPageTitle(realCourseData));
        util.setPageDescription(getPageDescription(realCourseData));
        this.animate([{opacity: 1}, {opacity: 0}], {duration: 100, fill: 'forwards'});
        await new Promise(res => setTimeout(res, 100));
        this.animate([{opacity: 0}, {opacity: 1}], {duration: 200, fill: 'forwards'});
        this.innerHTML = generateHTML(realCourseData);
    }

    storeUTMData(courseData){
        var utmCourseData = {
            meinnowCourseData: {
                id: courseData.uuid,
                type: courseData.vertical,
                duration: courseData.duration
            }
        };
        UTM.store(utmCourseData);
        var resetAppConfigEvent = new CustomEvent("reset-app-config");
        window.dispatchEvent(resetAppConfigEvent);
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`
