window.util = {};
util.formatDate = function(dateStr){
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};
util.formatDateWritten = function(dateStr){
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = [
        "Januar", "Februar", "März", "April", "Mai", "Juni",
        "Juli", "August", "September", "Oktober", "November", "Dezember"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day}. ${month} ${year}`;
};
util.setPageTitle = function(title){
    document.title = title;
}
util.setPageDescription = function(description){
    document.querySelector('meta[name="description"]').setAttribute('content', description);
}

export function slugify(title = "") {
    return title
      .normalize("NFD")                     
      .replace(/[\u0300-\u036f]/g, "")      
      .toLowerCase()
      .trim()
      .replace(/[\s\+]+/g, '-')
      .replace(/[?!:,;]+/g, '')
      .replace(/-+/g, '-')
      .replace(/^-+|-+$/g, '');
  }