const tagName = 'standorten-page';

const css = /*css*/ `
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #f8f9fa;
  font-family: sans-serif;
  overflow-x: hidden;
}

.locations-wrapper {
  display: flex;
  flex-wrap: wrap; 
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 40px;
  justify-content: center;
}

.location-card {
  display: flex;
  flex-direction: row;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex: 1 1 calc(50% - 0.5rem);
  min-width: 280px;
  min-height: 200px;
}

.location-info {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.location-info img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  object-fit: cover;
  align-self: center;
}

.location-info h2 {
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
}

.info-line {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.location-icon {
  background-image: url("/assets/images/Place Marker.png");
}

.email-icon {
  background-image: url("/assets/images/Letter.png");
}

.phone-icon {
  background-image: url("/assets/images/Phone.png");
}

.map-container {
  flex: 1;
  min-height: 200px;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
  display: block;
}

.locations-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0;
}

/* Responsive layout: stack cards on smaller screens */
@media (max-width: 768px) {
  .locations-wrapper {
    flex-direction: column;
    padding: 1rem;
    align-items: center;
  }
  .location-card {
    max-width: 100%;
    flex: 1 1 100%;
    min-width: unset;
  }
}

/* Edge case for very small screens */
@media (max-width: 490px) {
  .location-card {
    flex-direction: column;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }

  .info-line {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }

  .map-container {
    order: 2;
    min-height: 150px;
  }

  .locations-wrapper {
    padding: 0.5rem;
    width: 100%;
    padding-bottom: 40px;
    gap: 2rem;
  }

  .locations-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }
}

/* Link Styles */
.info-line a {
  color: inherit;
  text-decoration: underline; 
  transition: color 0.3s ease;
}

.info-line a:hover,
.info-line a:focus,
.info-line a:active {
  color: var(--blue-brand);
}
`;

function template() {
  return /*html*/ `
    <div class="locations-title">Unsere Standorte</div>
    <div class="locations-wrapper">
      <!-- LOCATION #1: Munich -->
      <div class="location-card">
        <div class="location-info">
          <img 
            src="/assets/images/munich.png" 
            alt="Munich Office"
          />
          <h2>Munich, DE</h2>
          <div class="info-line">
            <div class="icon location-icon"></div>
            <div>Arnulfstraße 61, 80636 München</div>
          </div>
          <div class="info-line">
            <div class="icon email-icon"></div>
            <a href="mailto:Beratung@franklin-institute.de">Beratung@franklin-institute.de</a>
          </div>
          <div class="info-line">
            <div class="icon phone-icon"></div>
            <a href="tel:+498962826149">+49 896 282 6149</a>
          </div>
        </div>
        <div class="map-container">
          <iframe
            src="https://www.google.com/maps?q=48.147299,11.539513&z=14&output=embed"
            allowfullscreen
            loading="lazy">
          </iframe>
        </div>
      </div>

      <!-- LOCATION #2: Berlin -->
      <div class="location-card">
        <div class="location-info">
          <img 
            src="/assets/images/berlin.png" 
            alt="Berlin Office"
          />
          <h2>Berlin, DE</h2>
          <div class="info-line">
            <div class="icon location-icon"></div>
            <div>Graefestraße 11, 10967 Berlin</div>
          </div>
          <div class="info-line">
            <div class="icon email-icon"></div>
            <a href="mailto:Beratung@franklin-institute.de">Beratung@franklin-institute.de</a>
          </div>
          <div class="info-line">
            <div class="icon phone-icon"></div>
            <a href="tel:+498962826149">+49 896 282 6149</a>
          </div>
        </div>
        <div class="map-container">
          <iframe
            src="https://www.google.com/maps?q=52.493262,13.423519&z=14&output=embed"
            allowfullscreen
            loading="lazy">
          </iframe>
        </div>
      </div>
    </div>
    <style>${css}</style>
  `;
}

class StandortenPage extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = template();
  }
}

customElements.define(tagName, StandortenPage);

export default `<${tagName}></${tagName}>`;
