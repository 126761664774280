const tagName = "dynamic-course-content";

var css = /*css*/`
    ${tagName}.module-box-appear .course-module-cont {
        animation: module-box-appear 500ms forwards linear;
    }
    @keyframes module-box-appear {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    ${tagName} .modules-cont {
        display: grid;
        grid-template-columns: 1fr 1fr;
        // flex-direction: column;
        gap: 16px;
        font-size: 16px;
        max-width: 800px;
        margin: 0 auto;
    }
    ${tagName} .course-module-cont {
        padding: 12px 20px;
        border-radius: 10px;
        // background-image: linear-gradient(170deg, #FAFAFA 0%, #EEE 100%);
        background: rgba(242, 246, 247, 0.8);
        opacity: 0;
        transition: opacity 200ms;
        // border: 1px solid #EEE;
    }
    ${tagName} .course-module-title {
        font-family: 'inter-bold';
        line-height: 2em;
    }
    ${tagName} .course-module-content-cont {
        padding-left: 20px;
        line-height: 1.6em;
    }
    ${tagName} .course-content-title {
        font-size: clamp(28px, 6vw, 42px);
        font-family: 'inter-bold';
        text-align: center;
        letter-spacing: -0.02em;
        line-height: 1.1em;
        padding: 0.3em 0px;
        margin-top: 0.7em;
    }
    ${tagName} .course-content-title span {
        color: var(--blue-brand);
    }
    ${tagName} .course-content-subtitle {
        text-align: center;
        font-size: 18px;
        font-family: 'inter-bold';
        margin-bottom: 1.5em;
    }
    @media(max-width: 699px){
        ${tagName} .modules-cont {
            grid-template-columns: 1fr;
        }
    }
`;

function renderModule(module, moduleCount){
    var contentHTML = "";
    var subModuleCount = 0;
    module.module_content.forEach((content)=>{
        subModuleCount++;
        contentHTML += /*html*/`
            <div class="course-module-content">${moduleCount}.${subModuleCount} ${content}</div>
        `.trim();
    });
    var html = /*html*/`
        <div class="course-module-cont" style="animation-delay: ${moduleCount * 70}ms">
            <div class="course-module-title">${moduleCount}. ${module.module_title}</div>
            <div class="course-module-content-cont">${contentHTML}</div>
        </div>
    `.trim();
    return html
}

const renderInnerHTML = (options)=>{
    var modules = options.modules;
    var modulesHTML = "";
    var moduleCount = 0;
    modules.forEach((module) => {
        moduleCount++;
        modulesHTML += renderModule(module, moduleCount);
    });
    var html = /*html*/`
        <div class="course-content-title">Der effektivste Start<br>in <span>deine neue Karriere</span></div>
        <div class="course-content-subtitle">So ist deine Weiterbildung aufgebaut</div>
        <div class="modules-cont">${modulesHTML}</div>
        <style>${css}</style>
    `.trim();
    html += /*html*/`<style>${css}</style>`;
    return html
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.removeAttribute('element-options');
        this.hydrate();
    }

    hydrate(){
        var observer = new IntersectionObserver((entries)=>{
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    this.classList.add('module-box-appear');
                    observer.unobserve(this);
                }
            });
        },  {rootMargin: "0px 0px -400px 0px", threshold: 0});
        observer.observe(this);
        return
        var moduleBoxes = Array.from(this.querySelectorAll('.course-module-cont'));
        moduleBoxes.forEach(moduleBox => {
            var observer = new IntersectionObserver((entries)=>{
                entries.forEach(entry => {
                    if(entry.isIntersecting){
                        setTimeout(()=>{
                            moduleBox.classList.add("module-box-appear");
                        }, 0);
                        observer.unobserve(moduleBox);
                        return
                    }
                });
            }, {rootMargin: "0px 0px 0px 0px", threshold: 0.5});
            observer.observe(moduleBox);
        });
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render