import ProductHeroSection from '/sections/product-hero-section.js'
import EmployerMarquee from '/sections/employer-marquee.js'
import YTVideoSection from '/sections/yt-video-section.js'
import PartnerSection from '/sections/partner-section.js'
import DoubleCTABox from '/components/double-cta-box.js'
import FAQBox from '/components/faq-box.js'
import WideCTASection from '/sections/wide-cta-section.js'
import TeacherSection from '/sections/teacher-section.js'
import ProductInfoSection from '/sections/product-info-section.js'
import StartDateCTASection from '/sections/start-date-cta-section.js'
import '/config.js'
import '/services/util.js'
import FeedbackSection from '/sections/feedback-section.js';
import CertificateSectionSales from '/sections/certificate-section-sales.js'
import renderBlueCertificate from '/components/blue-certificate-component-sales.js';
import AzavVerifiziertSection from '/sections/azav-verifiziert-section.js'
import FCC6SchritteComponent from '/components/fcc-6-schritte-component.js';
import VideoSection from '/sections/video-section.js';

const tagName = "sales-course-homepage";

const courseSlug = "sales";

var pageTitle = "Werde IT-Vertriebsmanager | Franklin Institute of Applied Sciences";
var pageDescription = `Starte deine Karriere mit der kostenlosen Weiterbildung des Franklin Institute zum ${config.courseData[courseSlug].name}. In nur ${config.courseData[courseSlug].duration} Monaten von der Arbeitslosigkeit zum Traumjob, inklusive MacBook und IHK-Zertifikat. Perfekt für Quereinsteiger und 100% kostenfrei dank Bildungsgutschein. Sichere dir jetzt deinen Platz und nutze unsere umfassende Karriereberatung für einen erfolgreichen Einstieg in die IT-Branche.`;

var videoURL = "https://fast.wistia.com/embed/medias/yc67ol9ioj";

var css = /*css*/`

.feedback-section-wrapper {
        margin-top: -10px; 
    }

    ${tagName} azav-verifiziert-section {
        margin-top: -20px; 
        padding-top: 0;
    }
    
    @media (max-width: 490px) {
        ${tagName} azav-verifiziert-section {
            margin-top: 10px;
        }
    }
`;

const certificateSectionData = {
    moduleBoxes: [
        {
            number: "1",
            title: "Grundlagen",
            bulletpoints: [
                "Grundlagen des IT-Vertriebs",
                "Technische Grundlagen von Softwareprodukten"
            ],
            certificates: [
                {
                    badgeText: "+ Zertifikat",
                    title: "Inbound Sales der Hubspot Academy",
                    logoURL: "/assets/images/hubspot-logo.png"
                }
            ]
        },
        {
            number: "2",
            title: "DeepDive 1.0",
            bulletpoints: [
                "Markt- und Kundenanalyse",
                "Customer Relationship Management Deepdive",
                "Kommunikation im Vertrieb & Angebote verhandeln + erstellen"
            ],
            certificates: [
                {
                    badgeText: "+ Zertifikat",
                    title: "Hubspot Sales Hub Software der Hubspot Academy",
                    logoURL: "/assets/images/hubspot-logo.png"
                },
                {
                    badgeText: "+ Zertifikat",
                    title: "NLP Basics von DVNLP",
                    logoURL: "/assets/images/dvnlp-logo.png"
                }
            ]
        },
        {
            number: "3",
            title: "DeepDive 2.0",
            bulletpoints: [
                "Prozessanalyse im Vertrieb & SaaS Leadakquise",
                "Sales Automation",
                "Agiles Projekt- & Anforderungsmanagement",
                "Leadership im Vertrieb",
                "Abschlussprojekt"
            ],
            certificates: [
                {
                    badgeText: "+ Zertifikat",
                    title: "Design Thinking Franklin IAS",
                    logoURL: "/assets/images/fias-logo-new.svg"
                },
                {
                    badgeText: "+ Zertifikat",
                    title: "Salesforce Certified Associate (Trailhead)",
                    logoURL: "/assets/images/salesforce-logo.webp"
                }
            ]
        }
    ],
    invertedModuleBox: {
        number: "4",
        title: "Franklin Career Center",
        bulletpoints: [
            "Karriere-Coaching & Persönlichkeitsanalyse",
            "Intensives Bewerber Coaching",
            "Arbeitsvermittlung an Partner Unternehmen",
            "Bewerbungsphase mit Individueller Betreuung"
        ],
        certificates: [
            {
                badgeText: "+ Report",
                title: "Ausführlicher Coaching Report",
                logoURL: "/assets/images/fias-logo-new-white.svg"
            }
        ],
        blueCertificate: {
            badgeText: "+ IHK-Zertifikat",
            title: "IHK-Zertifikat",
            logoURL: "/assets/images/ihk-logo.svg"
        }
    }
};


console.log('Certificate Section Data:', certificateSectionData);


var template = /*html*/`
    <div class="wrapper">
        ${ProductHeroSection({
            courseSlug,
            featureCards: [
                {
                    title: "55.049 € + Bonus",
                    text: "Durchschnittliches Gehalt (Jährlich, Brutto - Quelle: Bundesagentur für Arbeit)"
                },
                {
                    title: "MacBook inklusive",
                    text: "Um optimale Bedingungen für deine Teilnahme an unserer Weiterbildung zu schaffen, stellen wir dir ein MacBook zur Verfügung"
                },
                {
                    title: "Karrierechancen",
                    html: /*html*/`
                        <div style="display: flex; align-items: center; gap: 10px; margin-top: 5px;">
                            <div class="five-stars-icon-cont" style="display: flex; gap: 5px;">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>Sehr gut
                        </div>
                        <style>
                            ${tagName} .five-stars-icon-cont > div {
                                display: block;
                                width: 28px;
                                aspect-ratio: 1/1;
                                background-position: 50% 50%;
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-image: url('/assets/images/star-icon.svg');
                            }
                        </style>
                    `
                }
            ]
        })}
    </div>
    ${EmployerMarquee}
    <div class="wrapper" style="padding-top: 2rem;">
        ${/*YTVideoSection({courseSlug})*/""}
        ${ProductInfoSection({courseSlug})}
    </div>

    <azav-verifiziert-section></azav-verifiziert-section>

    ${StartDateCTASection({courseSlug})}
    <div class="wrapper">

    ${CertificateSectionSales(certificateSectionData)}
    <div class="blue-certificate">
        ${renderBlueCertificate(certificateSectionData.invertedModuleBox.blueCertificate)}
    </div>
</div>

    <div class="fcc-section-wrapper" style="padding-top: 25px;">
    <fcc-6-schritte-component></fcc-6-schritte-component>
    <!-- Title Section -->
    <div class="video-title" style="text-align: center; color: var(--gray-brand); font-family: 'inter-bold', sans-serif; font-size: clamp(28px, 6.18vw, 45px); margin: 20px 0;">
    Unsere Arbeit im <span style="color: var(--blue-brand);">FCC</span> für <br> einen
    <span style="color: var(--blue-brand);"> Start in deinem Traumjob</span>
    </div>

    <!-- Video Section -->
    ${VideoSection(videoURL)}
    </div>


<div class="wrapper">
<div class="partner-section-wrapper" style="padding-top: 10px;"> ${PartnerSection} 
</div>
</div>
        <div style="padding-top: 10px;">
        ${FeedbackSection}
        </div> 

        ${TeacherSection({courseSlug})}
    </div>
    ${WideCTASection({
        courseSlug,
        title: /*html*/`<div class="line">Starte jetzt mit der Weiterbildung zum</div> <div class="line"><span>${config.courseData[courseSlug].name}</span></div>`,
        btnText: /*html*/`<span>Jetzt kostenlose Beratung vereinbaren</span>`
    })}
    <div class="section" style="margin: 1rem 0;">
        <div class="wrapper">
        <div class="page-hero-medium font-scale-down centered" style="text-align: center; margin-bottom: 3rem; margin-top: 4rem;" id="faq">Häufig gestellte Fragen:</div>
            ${FAQBox([
                {
                    q: "Was bringt mir eine Weiterbildung beim Franklin Institute?",
                    a: "Wir leben Karriere. Das ist unser Motto - Unsere Weiterbildungen sollen Dir genau das ermöglichen - Karriere. Wir bilden Dich über mehrere Monate in einem relevanten Beruf in der IT-Branche aus und unterstützen Dich dann beim Einstieg in deine Traumkarriere. Mit uns an Deiner Seite formen wir Deine Karriere und geben Dir alles an die Hand, was Du brauchst um beruflich erfolgreich zu sein."
                },
                {
                    q: "Welche Voraussetzungen muss ich erfüllen?",
                    a: "Um an der Weiterbildung & am Career Success Programm teilnehmen zu können, musst Du lediglich (bald) bei der Agentur für Arbeit oder beim Jobcenter arbeitslos gemeldet sein. Solltest Du dies nicht sein, ist die Teilnahme leider nicht möglich. Du wirst in absehbarer Zeit arbeitslos? Dann bewerbe Dich gerne über unser kurzes Formular bei uns."
                },
                {
                    q: "Fallen für die Weiterbildung Kosten an?",
                    a: "Wenn Du arbeitslos gemeldet bist, hast Du die Möglichkeit einen Bildungsgutschein vom Staat zu erhalten. Dieser Bildungsgutschein finanziert Dir die komplette Weiterbildung und unser Career Success Programm - somit ist unser gesamtes Angebot kostenlos für Dich. Bewerbe Dich gerne über unser kurzes Formular und wir helfen Dir dabei einen Bildungsgutschein zu erhalten."
                },
                {
                    q: "Kann ich die Weiterbildung von Zuhause aus absolvieren?",
                    a: "Ja - die komplette Weiterbildung inkl. Career Success Programm findet Remote statt. Somit hast Du die Möglichkeit von überall aus teilzunehmen. Du erhältst auch einen Laptop von uns."
                },
                {
                    q: "Erhalte ich nach meiner Weiterbildung ein anerkanntes Zertifikat?",
                    a: "Ja, da wir AZAV zertifiziert sind, erhältst du nach deiner Weiterbildung ein offizielles Abschluss-Zertifikat von uns. Zusätzlich erhältst du während unseren Weiterbildungen einige, in der IT-Branche sehr angesehene, Zertifikate von namhaften Anbietern. So stellen wir sicher, dass du am Ende neben deiner Praxiserfahrung auch die nötigen Dokumente für einen erfolgreichen Einstieg in die IT-Branche vorlegen kannst."
                }
            ])}
        </div>
    </div>
    <div class="double-cta-cont" style="padding: 3em 1rem; background: linear-gradient(to bottom, white 0%, white 50%, var(--gray-brand) 50%, var(--gray-brand) 100%);">
        ${DoubleCTABox({
            texts: [
                "Haben Sie Fragen?",
                "Wir beraten Sie gerne persönlich.",
                "Nutzen Sie hierfür einfach unser Bewerbungsformular."
            ],
            ctas: [
                {label: "Jetzt bewerben.", href: config.ctaURLS[courseSlug], internal: false, newTab: true}
            ]
        })}
    </div>
    <style>${css}</style>
`;

class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;

        document.body.classList.add('sales-course-landingpage');
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`